<template>
    <authorize-layout class="SingUp">
        <v-card
            class="mx-auto my-auto pa-5"
            style="border-radius: 25px;"
            width="560px"
            height="518px"
            outlined
            elevation="5">
            <v-card-title class="justify-center auth-title" v-text="'Sign Up'"/>
            <v-card-text>
                <v-container class="px-5">
                    <auth-with provider="facebook" :on-after-auth="getCheckoutModal"/>
                    <auth-with provider="google" :on-after-auth="getCheckoutModal"/>

                    <p class="text-center text-muted" v-text="'or'"/>

                    <v-form>
                        <v-text-field :error-messages="errors.email" label="email" v-model="email"/>
                        <v-text-field
                            :error-messages="errors.password"
                            :append-icon="password ? showPassword ? 'mdi-eye' : 'mdi-eye-off' : 'mdi-eye-off'"
                            @click:append="() => (showPassword = !showPassword)"
                            :type="showPassword ? 'password' : 'text'"
                            label="password"
                            v-model="password"/>
                        <v-text-field
                            :error-messages="errors.password_confirmation"
                            :append-icon="password_confirmation ? showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off' : 'mdi-eye-off'"
                            @click:append="() => (showPasswordConfirm = !showPasswordConfirm)"
                            :type="showPasswordConfirm ? 'password' : 'text'"
                            label="confirm password"
                            v-model="password_confirmation"/>
                        <v-row>
                            <v-col cols="1">
                                <v-checkbox v-model="checkbox"></v-checkbox>
                            </v-col>
                            <v-col>
                                <div class="mt-1 text-style auth-text">
                                    I agree with
                                    <a style="border-bottom: 1px solid" @click="dialog = true">Terms and conditions</a>
                                </div>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions class="text-center justify-space-around">
                <div>
                    <v-btn
                        class="auth-title auth-btn"
                        :disabled="!password || !password_confirmation || !email || !checkbox"
                        @click="handleSignUp"
                        v-text="'Sign Up'"/>
                    <p class="text-style auth-text mt-4">
                        Have an account?
                        <router-link :to="{name: 'login'}" class="auth-underline" v-text="'Log in'"/>
                    </p>
                </div>
            </v-card-actions>
        </v-card>

        <v-dialog v-model="checkoutDialog" max-width="600" persistent>
            <stripe-checkout-card @submit-card="subscribe"/>
        </v-dialog>

        <div class="text-center">
            <v-dialog v-model="dialog" width="500">
                <v-card>
                    <v-card-title v-text="'Website Terms and Conditions'" class="headline grey lighten-2"/>

                    <v-card-text>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            text
                            @click="dialog = false"
                            v-text="'CLOSE'"/>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>

    </authorize-layout>
</template>

<script>
    import AuthorizeLayout from '../layouts/AuthorizeLayout.vue';
    import StripeCheckoutCard from "../components/StripeCheckoutCard";
    import BillingApi from "../api/BillingApi";
    import Api from "../api/Api";
    import {mapActions, mapMutations} from "vuex";
    import AuthWith from "../components/AuthWith";

    export default {
        name: "SignUp",
        components: {AuthWith, StripeCheckoutCard, AuthorizeLayout},
        props: {
            pricePlan: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                email: '',
                password: '',
                password_confirmation: '',
                showPassword: 'true',
                showPasswordConfirm: 'true',
                errors: {},
                user: [],
                checkbox: false,
                dialog: false,
                checkoutDialog: false,
                token: null,
            }
        },

       async mounted() {
            this.token = this.$route.query.t || null

            if (this.$auth.user()) {
                this.getCheckoutModal();
            }
        },

        methods: {
            ...mapActions(['setUser', 'getAuthUser']),
            ...mapMutations(['showSnackBar']),

            /**
             * Auth with social network.
             *
             * @param provider - facebook or twitter
             * @return {Promise<void>}
             */
            async authWith(provider = 'facebook') {
                Api.authWith(provider)
                    .then(response => {
                        this.$auth.ready()
                        window.location.href = response.data.url
                    })
                    .catch(error =>
                        this.showSnackBar({color: 'error', timeout: 3000, text: error.response.data.message})
                    )
            },

            async handleSignUp() {
                const {email, password, password_confirmation} = this;

                await this.$auth.register({
                    data: {email, password, password_confirmation},
                    success: () => this.$auth.ready(),
                    redirect: false,
                    staySignedIn: true,
                }).catch(e => this.errors = e.response.data.errors)

                if (Object.keys(this.errors).length === 0) {
                    await this.getCheckoutModal();
                }
            },

            async getCheckoutModal() {
                this.checkoutDialog = true;
            },

            subscribe(method) {
                BillingApi.updateSubscription(method, this.pricePlan)
                    .then(async () => {
                        this.showSnackBar({color: 'success', timeout: 3000, text: 'Subscription confirmed'})
                        // todo избавиться от парных запросов.
                        await this.$store.dispatch('getAuthUser');
                        await this.$auth.fetch();
                        // await this.$store.dispatch('setUser', userResponse.data.data);

                        await this.$router.push({name: 'dashboard.index'});
                    })
            },
            ...mapMutations(['showSnackBar'])
        }
    }
</script>

<style scoped lang="scss">

.row {
    margin: 0 !important;
}

.col {
    padding: 0 !important;
}

.checkbox-text {
    font-size: 14px !important;
    line-height: 17px !important;
}

.v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
}



</style>
