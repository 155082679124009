<template>
    <v-main class="auth-main">
        <div class="flex-wrapper-full-background d-flex-wrap" style="width: 100%">
            <div class="auth-header">
                <a href="/">
                    <img src="../assets/img/logomain.svg" alt="Convert my file">
                </a>
            </div>

            <slot> <!--CONTENT--> </slot>
        </div>
    </v-main>
</template>

<script>
    export default {
        name: "authorize-layout",
        data: function () {
            return {}
        },
    }
</script>

<style scoped>

</style>