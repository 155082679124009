<template>
    <v-btn block elevation="0" @click.prevent="authWith()" class="mb-5" :class="provider">
        <v-icon class="mr-3" right dark> mdi-{{ provider }}</v-icon>
        {{ $_.upperFirst(provider) }}
    </v-btn>
</template>

<script>
import Api from "../api/Api";
import {mapActions, mapMutations} from "vuex";

export default {
    name: 'AuthWith',
    props: {
        provider: {
            type: String,
            required: true
        },
        onAfterAuth: {
            type: Function,
            default: null
        }
    },
    async mounted() {
        this.token = this.$route.query.t || null

        if (this.token) {
            await this.setUser({token: this.token});
            await this.getAuthUser();

            if (this.onAfterAuth === null && this.$route.name === 'login') {
                try {
                    await this.$router.push({name: 'dashboard.index'});
                } catch (e) {
                    // console.log(e);
                }
            }

            if (typeof this.onAfterAuth === 'function') {
                this.onAfterAuth()
            }
        }
    },
    methods: {
        ...mapActions(['setUser', 'getAuthUser']),
        ...mapMutations(['showSnackBar']),
        /**
         * Auth with social network.
         *
         * @return {Promise<void>}
         */
        async authWith() {
            Api.authWith(this.provider)
                .then(response => window.location.href = response.data.url)
                .catch(error =>
                    this.showSnackBar({color: 'error', timeout: 3000, text: error.response.data.message})
                );
        },
    }
}
</script>

<style lang="scss" scoped>
.v-btn {
    &.facebook {
        background: #4267B2;
        color: white;
    }

    &.twitter {
        background: #1DA1F2;
        color: white;
    }
    &.google {
        background: white;
        color: black;
        border: 1px solid black;
    }
}
</style>
