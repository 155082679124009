<template>
    <v-card class="mx-auto px-15 py-15" max-width="600" elevation="2">
        <v-card-text>
            <v-text-field
                id="card-holder-name"
                label="Card Holder Name"
                v-model="name"
                :disabled="loading"
                :loading="loading"
                dense
                outlined
                type="text"
                class="form-control mb-2"/>
            <div id="card-element"/>
        </v-card-text>
        <v-card-actions>
            <v-btn id="add-card-button"
                   v-if="!loading"
                   @click="handleSubmit"
                   v-text="'Submit'"/>
        </v-card-actions>
    </v-card>
</template>

<script>
    import StripeApi from "../api/StripeApi";
    import BillingApi from "../api/BillingApi";

    export default {
        name: 'StripeCheckoutCard',
        data () {
            return {
                publishableKey: 'pk_test_51HX08wCgYzmVVpXhy3kDaHLTr73031tO7qb75IlJJQlX9LjZkNfPqi3J2Nh5b23KoM5lRZhP4monqOsH1g5gLCv900TbsNGIEu',
                stripe: '',
                elements: '',
                card: '',
                intentToken: '',
                name: '',
                addPaymentStatusError: '',
                loading: false
            }
        },
        methods: {
            handleSubmit() {
                this.loading = true;

                const payload = {
                    payment_method: {
                        card: this.card,
                        billing_details: {
                            name: this.name
                        }
                    }
                };

                this.stripe.confirmCardSetup(this.intentToken.client_secret, payload)
                    .then(result => {
                        if (result.error) {
                            this.addPaymentStatusError = result.error.message;
                        } else {
                            this.card.clear();
                            this.name = '';
                            this.$emit('submit-card', result.setupIntent.payment_method)
                        }
                    }).finally(() => this.loading = false)
            }
        },
        async mounted() {
            this.loading = true;
            this.intentToken = await BillingApi.loadIntentToken();

            StripeApi.includeStripe(() => {
                this.stripe = window.Stripe( this.publishableKey );
                this.elements = this.stripe.elements();
                this.card = this.elements.create('card');
                this.card.mount('#card-element');
            });

            this.loading = false
        }
    }
</script>

<style scoped>
    .v-sheet.theme--dark {
        background: blue;
    }
</style>
